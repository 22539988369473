import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Tree } from "react-arborist";
import { Modal } from "react-bootstrap";
import CodeEditor from "../code_editor";

const fetchTemplateFileContent = async (templateFileUrl) => {
  try {
    const response = await fetch(templateFileUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data.output;
  } catch (error) {
    console.error("Error occurred while fetching template file content", error);
    toast.error("Error occurred while fetching template file content");
    return null;
  }
};

const FileNode = ({ node, style, dragHandle }) => {
  const updatedStyle = {
    ...style,
    color: node.isLeaf && node.isSelected ? "red" : "black",
  };
  return (
    <div style={updatedStyle} ref={dragHandle}>
      {node.isLeaf ? (
        <i className="fa fa-file" />
      ) : (
        <i className="fa fa-folder" />
      )}
      {" " + node.data.name}
    </div>
  );
};

const Index = (props) => {
  const { previewUrl, backUrl, csrfToken, fileTree, uploadUrl } = props;
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileContent, setSelectedFileContent] = useState(null);

  const handleUpdate = () => {
    if (!selectedFile || !selectedFile.editable) {
      toast.error("Please select a file to update its content");
      return;
    }

    const data = {
      name: selectedFile.name,
      content: selectedFileContent,
    };

    fetch(selectedFile.dataUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        toast.success("Template file updated successfully");
      } else {
        toast.error("Error occurred while updating template file");
      }
    });
  };

  // Update selected file content when selected file changes
  useEffect(() => {
    const fetchContent = async () => {
      if (selectedFile && selectedFile.dataUrl && selectedFile.editable) {
        const content = await fetchTemplateFileContent(selectedFile.dataUrl);
        setSelectedFileContent(content);
      } else {
        setSelectedFileContent(null);
      }
    };

    fetchContent();
  }, [selectedFile]);

  return (
    <div className="content">
      <div className="block block-rounded block-bordered">
        <div className="block-header block-header-default">
          <h3 className="block-title">Template Code Editor</h3>
          <div className="block-options">
            <div className="block-options-item">
              <a
                href="#"
                onClick={() => setShowModal(true)}
                className="text-capitalize text-info font-w600 ml-2"
                role="button"
              >
                <u>Upload Template</u>
              </a>
              <a
                href={previewUrl}
                target="_blank"
                className="text-capitalize text-info font-w600 ml-2"
                role="button"
              >
                <u>Preview</u>
              </a>
              <a
                href="#"
                onClick={handleUpdate}
                className="text-capitalize text-info font-w600 ml-2"
                role="button"
              >
                <u>Update</u>
              </a>
              <a
                href={backUrl}
                className="text-capitalize text-info font-w600 ml-2"
                role="button"
              >
                <u>Back</u>
              </a>
            </div>
          </div>
        </div>
        <div className="block-content">
          <div className="row">
            <div className="col-sm-2">
              <pre>
                <Tree
                  disableDrag
                  disableDrop
                  disableEdit
                  disableMultiSelection
                  data={fileTree}
                  onSelect={(nodes) => {
                    if (
                      nodes &&
                      nodes[0] &&
                      nodes[0].isLeaf &&
                      nodes[0].isSelected
                    ) {
                      setSelectedFileContent(null);
                      setSelectedFile(nodes[0].data);
                    }
                  }}
                >
                  {FileNode}
                </Tree>
              </pre>
            </div>
            <div className="col-sm-10">
              {!selectedFile && (
                <div className="text-center text-muted pb-4">
                  Please select a file to view its content
                </div>
              )}
              {selectedFile && !selectedFile.editable && (
                <div className="text-center text-muted pb-4">
                  <span>
                    This file is not editable. You can view it at{" "}
                    <a href={selectedFile.assetUrl} target="_blank">
                      {selectedFile.name}
                    </a>
                  </span>
                </div>
              )}
              {selectedFile && selectedFile.editable && (
                <div className="pb-4">
                  <CodeEditor
                    initialContent={selectedFileContent}
                    mode={selectedFile.editorMode}
                    handleEditorChange={(content) =>
                      setSelectedFileContent(content)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        closable
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Upload Marketing Site Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action={uploadUrl} method="post" encType="multipart/form-data">
            <div className="form-group row pb-2">
              <label className="col-sm-4 col-form-label">
                Select template zip file
              </label>
              <div className="col-sm-8 pt-1">
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={csrfToken}
                />
                <input type="file" name="zip_file" accept=".zip" required />
              </div>
            </div>
            <div className="pb-4">
              <button
                type="submit"
                className="btn btn-success float-right"
                confirm="Are you sure you want to replace the current template?"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Toaster position="top-right" />
    </div>
  );
};

export default Index;
