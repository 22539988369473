import React, { useEffect, useState } from "react";
import Masonry from "react-responsive-masonry";
import ReactLoading from "react-loading";

const Index = (props) => {
  const { dataUrl, newUrl } = props;
  const [loading, setLoading] = useState(true);
  const [mediaItems, setMediaItems] = useState([]);

  const fetchMediaItems = () => {
    fetch(dataUrl)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setMediaItems(data);
      });
  };

  useEffect(() => {
    fetchMediaItems();
  }, []);

  return (
    <div className="block block-rounded block-fx-pop block-bordered">
      <div className="block-header block-header-default">
        <h3 className="block-title">Listing all media items</h3>
        <div className="block-options">
          <div className="block-options-item">
            <a
              href={newUrl}
              className="text-capitalize text-info font-w600"
              role="button"
            >
              <u>New Media Item</u>
            </a>
          </div>
        </div>
      </div>
      <div className="block-content">
        {loading && (
          <div className="row d-flex justify-content-center flex-nowrap pb-4 pt-2">
            <ReactLoading
              type="spokes"
              color="#00000"
              delay={100}
              height={"30px"}
              width={"30px"}
            />
          </div>
        )}
        <Masonry columnsCount={3} gutter="10px">
          {mediaItems.map((mediaItem) => {
            return (
              <div key={mediaItem.id}>
                <a href={mediaItem.url}>
                  <img
                    src={mediaItem.src}
                    alt={mediaItem.name}
                    className="img-fluid"
                  />
                </a>
              </div>
            );
          })}
        </Masonry>
      </div>
    </div>
  );
};

export default Index;
