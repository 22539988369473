import React, { useCallback, useEffect, useState } from "react"
import toast, { Toaster } from 'react-hot-toast';
import CodeEditor from "../code_editor";
import ReactLoading from 'react-loading';
import debounce from "lodash.debounce";

const Editor = (props) => {
  const dataUrl = props.dataUrl;
  const previewUrl = props.previewUrl;
  const visualEditorUrl = props.visualEditorUrl;
  const updateUrl = props.updateUrl;
  const backUrl = props.backUrl;
  const csrfToken = props.csrfToken;
  const templateName = props.templateName;
  const templateHeader = `Edit Email Template: ${templateName}`

  const [loading, setLoading] = useState(true);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [content, setContent] = useState('');
  const [outputHtml, setOutputHtml] = useState('');

  // Load initial data
  useEffect(async () => {
    if (!dataUrl) { return }

    const response = await fetch(dataUrl)
    const json = await response.json();
    if (json.mjml_text) {
      setContent(json.mjml_text);
    }
    if (json.preview) {
      setOutputHtml(json.preview);
    }
    setLoading(false);
  }, []);

  const fetchPreview = (text) => {
    setLoadingPreview(true);
    fetch(previewUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ mjml_text: text })
    })
      .then(async (response) => {
        if (response.ok) {
          const json = await response.json();
          setOutputHtml(json.data);
          setLoadingPreview(false);
          return;
        }
        throw new Error("Network response was not ok.");
      })
      .catch(() => toast.error("Error occurred while rendering template"));
  };
  const throtttledFetchPreview = useCallback(debounce(fetchPreview, 2000), []);

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(updateUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ email_template: { raw_input: content } })
    })
      .then(() => toast.success('Updated content successfully'))
      .catch(() => toast.error("Error occurred while updating content"));

  };
  const handleEditorChange = (text) => {
    setContent(text);
    throtttledFetchPreview(text);
  };

  return (
    <div className="block block-rounded block-fx-pop block-bordered">
      <div className="block-header block-header-default text-left">
        <h3 className="block-title">{templateHeader}</h3>
        <div className="block-options">
          <div className="block-options-item">
            <a href={visualEditorUrl} className="text-capitalize text-info font-w600 ml-2" role="button"><u>Visual Editor</u></a>
            <a href={updateUrl} onClick={handleSubmit} className="text-capitalize text-info font-w600 ml-2" role="button"><u>Update</u></a>
            <a href={backUrl} className="text-capitalize text-info font-w600 ml-2" role="button"><u>Back</u></a>
          </div>
        </div>
      </div>
      {loading &&
        <div className="row d-flex justify-content-center flex-nowrap pb-4 pt-2">
          <ReactLoading type="spokes" color="#00000" delay={100} height={'20px'} width={'20px'} />
        </div>
      }
      {
        !loading && <div className="block-content">
          <div className="row">
            <div className="col-lg-5 col-xl-5 pb-4">
              <div className="d-flex flex-column">
                <div>
                  {loadingPreview &&
                    <h5 className="text-center">Updating Preview...</h5>
                  }
                  {
                    !loadingPreview &&
                    <h5 className="text-center">Preview</h5>
                  }
                </div>
                <div style={{ height: '700px', overflow: 'scroll' }} dangerouslySetInnerHTML={{ __html: outputHtml }} />
              </div>
            </div>
            <div className="col-lg-7 col-xl-7 pb-4">
              <div className="d-flex flex-column">
                <div>
                  <h5 className="text-center">Editor</h5>
                </div>
                <CodeEditor mode="html" initialContent={content} handleEditorChange={handleEditorChange} />
              </div>
            </div>
          </div>
          <Toaster position="top-right" />
        </div>
      }
    </div>
  );
};

export default Editor;
