import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import CodeEditor from "../code_editor";

const Editor = (props) => {
  const dataUrl = props.dataUrl;
  const updateUrl = props.updateUrl;
  const backUrl = props.backUrl;
  const csrfToken = props.csrfToken;

  const [content, setContent] = useState(null);

  // Load initial data
  useEffect(async () => {
    if (!dataUrl) {
      return;
    }

    const response = await fetch(dataUrl);
    const text = await response.text();
    if (text === "null") {
      setContent("{}");
    } else {
      setContent(text);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({ data: content }),
    })
      .then(() => toast.success("Updated content successfully"))
      .catch(() => toast.error("Error occurred while updating content"));
  };

  return (
    <div className="content">
      <div className="block block-rounded block-bordered">
        <div className="block-header block-header-default">
          <h3 className="block-title">Checkout Offers</h3>
          <div className="block-options">
            <div className="block-options-item">
              <a
                href={updateUrl}
                onClick={handleSubmit}
                className="text-capitalize text-info font-w600"
                role="button"
              >
                <u>Update</u>
              </a>
              <a
                href={backUrl}
                className="text-capitalize text-info font-w600 ml-2"
                role="button"
              >
                <u>Back</u>
              </a>
            </div>
          </div>
        </div>
        <div className="block-content">
          <CodeEditor
            mode="json"
            initialContent={content}
            handleEditorChange={(text) => setContent(text)}
          />
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

export default Editor;
