import React from "react"
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/theme-terminal";

const CodeEditor = (props) => {
  const initialContent = props.initialContent || '';
  const width = props.width || '100%';
  const height = props.height || '700px';
  const fontSize = props.fontSize || 14;
  const mode = props.mode || 'html';
  const theme = 'terminal';
  const handleEditorChange = props.handleEditorChange;

  return (
    <AceEditor
      mode={mode}
      theme={theme}
      width={width}
      height={height}
      fontSize={fontSize}
      editorProps={{ $blockScrolling: true }}
      minLines={Infinity}
      showPrintMargin={false}
      value={initialContent}
      onChange={handleEditorChange}
    />
  );
};

export default CodeEditor;
