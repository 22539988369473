import React, { useEffect, useState } from "react"
import toast, { Toaster } from 'react-hot-toast';
import { Badge, ListGroup } from 'react-bootstrap';

const SelectedOptions = (props) => {
  const { selectedOptions, selectionOptionsLookup } = props;
  return (
    <>
      <ListGroup as="ul">
        {selectedOptions && Object.keys(selectedOptions).map((key, index) => {
          const item = selectedOptions[key];
          return (
            <ListGroup.Item as="li" key={index}>
              <div className="row">
                <div className="col-8">
                  <span>{item.display_name}</span>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {item.menu_category_ids && item.menu_category_ids.map((category_id) => {
                    return (
                      <Badge bg="info" className="ml-2 text-white" key={category_id}>{selectionOptionsLookup[category_id]}</Badge>
                    )
                  })}
                </div>
              </div>
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </>
  )
}

const Show = (props) => {
  const { selectionOptionsLookup, dataUrl, backUrl, codeEditorUrl } = props;
  const [selectedOptions, setSelectedOptions] = useState(null);

  // Load initial data
  useEffect(async () => {
    if (!dataUrl) { return }
    const response = await fetch(dataUrl)
    const text = await response.text();
    text === 'null' ? setSelectedOptions({}) : setSelectedOptions(JSON.parse(text));
  }, []);

  return (
    <div className="content">
      <div className="block block-rounded block-bordered">
        <div className="block-header block-header-default">
          <h3 className="block-title">Menu Item Print Order</h3>
          <div className="block-options">
            <div className="block-options-item">
              <a href={codeEditorUrl} className="text-capitalize text-info font-w600 ml-2" role="button"><u>Code Editor</u></a>
              <a href={backUrl} className="text-capitalize text-info font-w600 ml-2" role="button"><u>Back</u></a>
            </div>
          </div>
        </div>
        <div className="block-content mb-4">
          <SelectedOptions
            selectedOptions={selectedOptions}
            selectionOptionsLookup={selectionOptionsLookup} />
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

export default Show;
