import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "react-bootstrap";

import CodeEditor from "../code_editor";

const Index = (props) => {
  const { sampleData, data, updateUrl, backUrl, csrfToken } = props;
  const [content, setContent] = useState(JSON.stringify(data, null, 2));
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(updateUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        template_configuration: {
          routes: JSON.parse(content),
        },
      }),
    })
      .then(() =>
        toast.success("Updated template route configuration successfully")
      )
      .catch(() =>
        toast.error(
          "Error occurred while updating template route configuration"
        )
      );
  };

  const handleEditorChange = (text) => {
    setContent(text);
  };

  return (
    <div className="content">
      <div className="block block-rounded block-bordered">
        <div className="block-header block-header-default">
          <h3 className="block-title">Template Route Editor</h3>
          <div className="block-options">
            <div className="block-options-item">
              <a
                href="#"
                onClick={() => setShowModal(true)}
                className="text-capitalize text-info font-w600"
                role="button"
              >
                <u>View Sample</u>
              </a>
              <a
                href={updateUrl}
                onClick={handleSubmit}
                className="text-capitalize text-info font-w600 ml-2"
                role="button"
              >
                <u>Update</u>
              </a>
              <a
                href={backUrl}
                className="text-capitalize text-info font-w600 ml-2"
                role="button"
              >
                <u>Back</u>
              </a>
            </div>
          </div>
        </div>
        <div className="block-content">
          <CodeEditor
            mode="json"
            initialContent={content}
            handleEditorChange={handleEditorChange}
          />
        </div>
      </div>
      <Toaster position="top-right" />
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header>
          <Modal.Title>Sample Template Route Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{sampleData}</pre>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Index;
